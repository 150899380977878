<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">
            <b-card
              no-body
              class="mb-0"
            >
              <div class="m-2">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-2"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                id="users_table"
                class="position-relative"
                primary-key="uuid"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há usuários listados."
                :items="users"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="onRowSelected"
              >

                <template #cell(field_name)="data">
                  <b-media>
                    <template #aside>
                      <b-avatar
                        :src="userImages[data.item.uuid]"
                        size="2.5rem"
                      />
                    </template>
                    <div
                      class="font-weight-bold text-nowrap biddingOwnerField mt-50"
                    >
                      {{ data.item.profile.get_full_name }}
                    </div>
                  </b-media>
                </template>

                <template #cell(field_profile)="data">
                  <b-media vertical-align="center">
                    <template v-if="data.item.profile.permission_set">
                      {{ data.item.profile.permission_set.name }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </b-media>
                </template>

              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BTable,
  BMedia,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BAvatar,
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    SpinLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const profile = syslic.authentication.getProfile()

    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      { key: 'field_name', label: 'Nome', sortable: false },
      { key: 'field_profile', label: 'Perfil', sortable: false },
    ]

    const users = []
    const userImages = {}

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('users_list')
    syncLoad.addTrigger('verify_permissions')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      profile,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      users,
      userImages,
      isLoaded,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToRead() {
      return this.profile.get_permissions_for_modules.commission.can_read
    },
    hasPermissionToUpdate() {
      return this.profile.get_permissions_for_modules.commission.can_edit
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
  },
  created() {
    this.verifyHasPermissionEdit()
    this.fetchData()
    this.verifyPermissions()
  },
  methods: {
    verifyHasPermissionEdit() {
      if (!this.profile.get_permissions_for_modules.commission.can_edit) {
        router.push({
          name: 'commission-detail',
          params: {
            id: this.profile.get_uuid,
          },
        })
      }
    },
    fetchData() {
      syslic
        .user
        .fetchUsersList({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
        })
        .then(response => {
          this.totalItems = response.data.count
          this.users = response.data.results
          return this.loadUsersPhotos(this.users)
        })
        .then(() => {
          this.syncLoad.ready('users_list')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados dos usuários.',
              text: 'Não foi possível ler os dados do usuários do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('users_list')
        })
    },
    loadUsersPhotos(users) {
      const promiseList = []
      users.forEach(user => {
        const promise = new Promise((resolve, reject) => {
          syslic.user.getUserImage(user.uuid)
            .then(response => {
              this.userImages[user.uuid] = response
              resolve()
            })
            .catch(() => {
              reject()
            })
        })

        promiseList.push(promise)
      })

      return Promise.allSettled(promiseList)
    },
    verifyPermissions() {
      if (this.hasPermissionToRead || this.hasPermissionToUpdate) {
        this.syncLoad.ready('verify_permissions')
      } else {
        router.push({
          name: 'commission-detail',
          params: {
            id: this.profile.get_uuid,
          },
        })
      }
    },
    onRowSelected(item) {
      router.push({
        name: 'commission-detail',
        params: {
          id: item.uuid,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
